<ng-container *ngIf="competition; else noComp">
  <div class="card-wrapper relative rounded-border hover-shadow-3" #competitionCardContainer
    [ngStyle]="{'opacity': host == 'carousel' ? 0.2 : 1}" fxLayout="column" fxLayoutAlign="space-between">
    <div class="content sparro-bg-white relative" *ngIf="can_edit">
      <uni-competition-card-header
        [competition]="competition"
        [can_edit]="can_edit"
        [can_delete]="can_delete"
        [cardWidth]="cardWidth">
      </uni-competition-card-header>

      <uni-competition-card-content
        [competition]="competition">
      </uni-competition-card-content>

      <uni-competition-card-footer
        [type]="footerType"
        [can_edit]="can_edit"
        [can_delete]="can_delete"
        [competition]="competition">
      </uni-competition-card-footer>
    </div>

    <!-- I can't see any difference between can_edit and !can_edit code here. -->
    <a class="content no-decor" *ngIf="!can_edit" [href]="competitionUrl + competition.slug" (click)="openCompetition()" target="_blank">
      <uni-competition-card-header
        [competition]="competition"
        [can_edit]="can_edit"
        [can_delete]="can_delete"
        [cardWidth]="cardWidth">
      </uni-competition-card-header>

      <uni-competition-card-content
        [competition]="competition">
      </uni-competition-card-content>

      <uni-competition-card-footer
        [type]="footerType"
        [can_edit]="can_edit"
        [can_delete]="can_delete"
        [competition]="competition">
      </uni-competition-card-footer>
    </a>

    <div *ngIf="competition.announcements && competition.announcements.length > 0" class="notify font-xxs text-center relative padding-1 sparro-bg-grey-200">
      <div [innerHtml]="competition.announcements[0].message | safeContent"></div>
      <svg id="notch_curve_ex_bottomLeft" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 12"><defs><style>.notch_curve_card_ex_bottom{fill:#eee;}</style></defs><path class="notch_curve_card_ex_bottom" d="M0,0A12,12,0,0,1,12,12h2V0Z"/></svg>
      <svg id="notch_curve_ex_bottomRight" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 12"><defs><style>.notch_curve_card_ex_bottom{fill:#eee;}</style></defs><path class="notch_curve_card_ex_bottom" d="M14,0A12,12,0,0,0,2,12H0V0Z"/></svg>
    </div>
  </div>
</ng-container>

<ng-template #noComp>
</ng-template>
