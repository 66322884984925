import { Component, Input, OnInit, ViewChild, ElementRef, HostListener, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { UNI_CONSTANTS } from '@sparro/app/core/config/constants.config';

import { environment } from '@sparro/env/environment';


@Component({
  selector: 'uni-competition-card',
  templateUrl: './competition-card.component.html',
  styleUrls: ['./competition-card.component.scss']
})
export class CompetitionCardComponent implements OnInit, AfterViewInit {
  @Input() competition: any;
  @Input() footerType: string;
  @Input() can_edit: boolean = false;
  @Input() can_delete: boolean = false;
  @Input() host: string;
  competitionUrl: string = `${environment.competitionUrl}`;
  cardWidth: number;

  @ViewChild ('competitionCardContainer') competitionCardContainer: ElementRef;

  constructor(
    public router: Router
  ) { }

  ngOnInit(): void { }

  ngAfterViewInit() {
    this.cardWidth = this.competitionCardContainer?.nativeElement.offsetWidth;
  }

  @HostListener('window:resize', [])
  onWindowResize(): void {
    this.cardWidth = this.competitionCardContainer?.nativeElement.offsetWidth;
  }

  openCompetition(): boolean {
    if (this.host === UNI_CONSTANTS.HOSTS.ARENA) {
      this.router.navigate(['/', this.competition.slug]);
      return false;
    }
    return true;
  }
}
