<div class="cursor-pointer relative">
  <uni-image [src]="competition?.image" [default]="defaultImage" [alt]="competition.title" [height]="cardWidth ? (cardWidth/2) : 210" [width]="420"
    classes="width-100  rounded-top-3 fit-cover">
  </uni-image>

  <div class="img-header-icon absolute top-0 sparro-bg-white box-shadow-3" *ngIf="competition.prizes && competition.prizes[0] && competition.prizes[0].prize !== 0">
    <p class="font-xxs margin-0 sparro-grey-900">Prize: <span class=" sparro-grey-900 font-bold">{{ competition.prizes[0].prize | currency: competition.prizes[0].currency.alpha_code: 'symbol' : '1.0-0'  }} </span></p>
    <svg id="notch_curve_ex_left" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 12"><defs><style>.notch_curve_card_ex{fill:#fff;}</style></defs><path class="notch_curve_card_ex" d="M0,0A12,12,0,0,1,12,12h2V0Z"/></svg>
    <svg id="notch_curve_ex_right" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 12"><defs><style>.notch_curve_card_ex{fill:#fff;}</style></defs><path class="notch_curve_card_ex" d="M14,0A12,12,0,0,0,2,12H0V0Z"/></svg>
  </div>

  <ng-container *ngIf="competition.organizer && competition.organizer.title && competition.organizer.logo">
      <a [href]="getOrganizerUrl(competition.organizer)" target="_blank" class="no-decor absolute" style="left: 12px; bottom: -24px;"
        (click)="$event.stopPropagation()" matTooltip="{{competition.hosted ? 'Hosted' : 'Listed'}} by {{competition.organizer.title}}"
        matTooltipPosition="right">
        <uni-image 
          [src]="competition.organizer.logo"
          [default]="defaultImage"
          [alt]="competition.organizer.title"
          [width]="40"
          [height]="40"
          [auxClassList]="'card-badge-host-image'">
        </uni-image>
        <!-- <button class="card-badge-buttons cursor-pointer relative margin-0 sparro-bg-grey-300">
        </button> -->
      </a>
    </ng-container>
  </div>
  <!-- <div class="dropdown-content">
    <p> {{competition.hosted ? 'Hosted' : 'Listed'}} by {{competition.organizer.title}}</p>
  </div>
</div> -->
