import { Component, Input, OnInit } from '@angular/core';
import { environment } from '@sparro/env/environment';
// import { StudioCompetition, ArenaCompetition, Competition } from '@sparro/app/core/models/competition.model';

@Component({
  selector: 'uni-competition-card-footer',
  templateUrl: './competition-card-footer.component.html',
  styleUrls: ['./competition-card-footer.component.scss']
})

export class CompetitionCardFooterComponent implements OnInit {
  @Input() type: string;
  @Input() competition: any;
  @Input() can_edit: boolean = false;
  @Input() can_delete: boolean = false;
  status: string;
  registrationDeadline: any;

  constructor() { }

  ngOnInit(): void {
    if (!this.type && !this.can_edit) {
      this.type = 'two-grid';
    }
    this.setDaysLeftForRegistration();
  }

  setDaysLeftForRegistration(): void {
    if (this.competition.registration_deadline !== 'Closed') {
      // this.submission_deadline = entry.competition.submission_deadline;
      const today: any = new Date();
      const t: any = Date.parse(this.competition.registration_deadline) - Date.parse(today);
      const days = Math.floor(t / (1000 * 60 * 60 * 24));
      let registration = '';
      if (t < 86400000) {
        registration = `${Math.floor((t / (1000 * 60 * 60)) % 24)} hours`;
      } else {
      registration = `${days} ${days === 1 ? 'day' : 'days'}`;
      }
      this.registrationDeadline = {
        date: days,
        daysLeft: registration
      };
    }
  }

  getDayMonth(date: string): any {
    if(date) {
      const dayMonth = date.split(',')[0];
      return dayMonth;
    }
  }

  getYear(date: string): string {
    if(date) {
      const year = date.split(',')[1];
      return year;
    }
  }

  launchCompetition(): void {
    if (this.competition.status === 'pre-draft') {
      return;
    }
    else {

    }
  }

  report() {

  }

  getPreviewUrl() {
    return `${environment.competitionUrl}${this.competition.slug}`;
  }

  preview(): boolean {
    return Boolean(this.competition.slug);
  }

  edit(): void {
    if (this.competition.uuid) {
      let url;
      if (this.competition.status === 'pre_draft') {
        url = `${environment.studioUrl}${this.competition.uuid}`;
      } else {
        url = `${environment.studioUrl}${this.competition.uuid}/pages/`;
      }
      window.open(url, '_blank');
    }
  }

  updateCompetitionInfo(): void {

  }

  goToDashboard(): void {

  }

  viewCompetitionEntries(): void {

  }
}
