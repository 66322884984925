  <div class="footer-section" *ngIf="(type === 'two-grid') || (type === 'organization' && can_edit)"
    fxLayout="column" fxLayoutAlign="space-around start" fxLayoutGap="8px">
    <div fxLayout="row" fxLayout="start center" fxLayoutGap="8px">
      <p class="footer-title margin-0">Register by: </p>
      <ng-container *ngIf="competition.registration_deadline !== 'Closed'; else closedReg">
        <p class="footer-title sparro-grey-900 margin-0 font-xs-static">
          <span class="font-bold">
            {{ getDayMonth(competition.registration_deadline | date) }}
          </span> 
          {{ getYear(competition.registration_deadline | date )}}
        </p>
        
      </ng-container>
      <ng-template #closedReg>
        <p class="footer-title margin-0 font-xs-static" >{{ competition.registration_deadline }}</p>
      </ng-template>
    </div>
  
    <div fxLayout="row" fxLayout="start center" fxLayoutGap="8px">
      <p class="footer-title margin-0">Submit by: </p>
      <ng-container *ngIf="competition.submission_deadline !== 'Closed'; else closedSub">
        <p class="footer-title sparro-grey-900 margin-0 font-xs-static">
          <span class="font-bold">
            {{ getDayMonth(competition.submission_deadline | date) }}
          </span> 
          {{ getYear(competition.submission_deadline | date)}}
        </p>
        
      </ng-container>
      <ng-template #closedSub>
        <p class="footer-title margin-0 font-xs-static">{{ competition.submission_deadline }}</p>
      </ng-template>
    </div>
  </div>
  <!-- type === 'three-grid' -->
  <div class="footer-section-three-grid" *ngIf="type === 'three-grid'" fxLayout="column" fxLayoutAlign="space-around start">
    <ng-container *ngIf="((competition.status === 'draft') || (competition.status === 'pre-draft')); else public">
      <div fxLayout="column" fxLayoutGap="16px">
        <div class="competition-info-wrapper" fxLayout="row" fxLayoutGap="24px" fxLayoutAlign="center">
          <div>
            <p class="footer-title">Status</p>
            <div fxLayout="column" fxLayoutAlign="center center" >
              <p class="margin-0 font-xs-static" *ngIf="status === 'pre-draft'" style="color: #d7373f;">Incomplete</p>
              <p class="margin-0 font-xs-static" *ngIf="status === 'draft'" style="color: #51a351;">Complete</p>
            </div>
          </div>
  
          <!-- <mat-divider [vertical]="true"></mat-divider> -->
  
          <div>
            <p class="footer-title ">Jurors</p>
            <div fxLayout="column" fxLayoutAlign="center center">
              <p class="margin-0 font-xs-static">{{ competition.jury_count }}</p>
            </div>
          </div>
  
          <!-- <mat-divider [vertical]="true"></mat-divider> -->
  
  
          <div>
            <p class="footer-title">Fee Structure</p>
            <div fxLayout="column" fxLayoutAlign="center center">
              <p class="margin-0 font-xs-static" style="text-transform: capitalize;">{{ competition.pricing }}</p>
            </div>
          </div>
        </div>
  
        <div class="competition-buttons-wrapper" fxLayout="column" fxLayoutGap="24px">
          <uni-navigation-button
            default="Launch Now"
            button_class="secondary"
            (click)="launchCompetition()"
            [disabled]="competition.status === 'pre-draft'">
          </uni-navigation-button>
  
          <uni-navigation-button
            default="Update Info"
            button_class="secondary"
            (click)="updateCompetitionInfo()">
          </uni-navigation-button>
        </div>
      </div>
    </ng-container>
  
    <ng-template #public>
      <div fxLayout="column" fxLayoutGap="16px">
        <div class="competition-info-wrapper" fxLayout="column" fxLayoutGap="24px" fxLayoutAlign="center">
          <div fxLayout="row" fxLayout="start center" fxLayoutGap="8px">
            <ng-container *ngIf="competition.submission_deadline !== 'Closed'; else closedSubmission">
              <p class="footer-title margin-0 ">Registrations: </p>
              <p class="footer-title sparro-grey-900 margin-0 font-xs-static">{{ competition.registered_count }}</p>
            </ng-container>
            <ng-template #closedSubmission>
              <p class="footer-title margin-0 ">Result Date:</p>
              <p class="footer-title sparro-grey-900 margin-0 font-xs-static">{{ competition.result_date === 'Closed' ? 
                competition.result_date : getDayMonth(competition.result_date | date) }}</p>
            </ng-template>
          </div>
  
          <!-- <mat-divider [vertical]="true"></mat-divider> -->
  
          <div fxLayout="row" fxLayout="start center" fxLayoutGap="8px">
            <ng-container *ngIf="competition.registration_deadline !== 'Closed'; else closedRegistration">
              <p class="footer-title margin-0 ">Days Left:</p>
              <p class="footer-title sparro-grey-900 margin-0 font-xs-static">{{ registrationDeadline.daysLeft }}</p>
            </ng-container>
            <ng-template #closedRegistration>
              <p class="footer-title margin-0">Submissions:</p>
              <p class="footer-title sparro-grey-900 margin-0 font-xs-static">123</p>
            </ng-template>
          </div>
  
          <!-- <mat-divider [vertical]="true"></mat-divider> -->
  
          <div fxLayout="row" fxLayout="start center" fxLayoutGap="8px">
            <p class="footer-title margin-0 ">Type:</p>
            <p class="footer-title sparro-grey-900 margin-0 font-xs-static" *ngIf="competition.hosted">{{ competition.hosted ? 'Host' : 'List' }}</p>
          </div>
        </div>
  
        <div class="competition-buttons-wrapper" fxLayout="row" fxLayoutGap="24px">
          <uni-navigation-button
            default="Visit Dashboard"
            button_class="secondary"
            (click)="goToDashboard()">
          </uni-navigation-button>
  
          <uni-navigation-button
            *ngIf="competition.submisssion_deadline !== 'Closed'"
            default="Update Info"
            button_class="secondary"
            (click)="updateCompetitionInfo()">
          </uni-navigation-button>
  
          <uni-navigation-button
            *ngIf="competition.submisssion_deadline === 'Closed'"
            default="See Entries"
            button_class="secondary"
            (click)="viewCompetitionEntries()">
          </uni-navigation-button>
        </div>
      </div>
    </ng-template>
  </div>
  
  <div class="footer-section" *ngIf="type === 'none'" fxLayout="row">
  
  </div>