import { Organization } from '@sparro/app/core/models/organization.model';
import { Component, Input, OnInit } from '@angular/core';
import { UNI_CONSTANTS } from '@sparro/app/core/config/constants.config';
import { ImageOptimizerService } from '@sparro/app/core/helpers/image-optimizer';
import { environment } from '@sparro/env/environment';

@Component({
  selector: 'uni-competition-card-header',
  templateUrl: './competition-card-header.component.html',
  styleUrls: ['./competition-card-header.component.scss']
})
export class CompetitionCardHeaderComponent implements OnInit {
  @Input() competition: any;
  @Input() can_edit: boolean = false;
  @Input() can_delete: boolean = false;
  @Input() cardWidth: number = null;

  defaultImage: string = UNI_CONSTANTS.NO_IMAGE.COMP_OR_PROJECT;
  source: string = 'competitions';

  constructor(
    private imageOptimizer: ImageOptimizerService,
  ) { }

  ngOnInit() {
  }

  renderImage(image: string): string {
    return this.imageOptimizer.renderImage(image, 420, 210);
  }

  getOrganizerUrl(organization: Organization) {
    return `${environment.organizationUrl}${organization.slug}/`;
  }
}
