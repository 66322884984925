import { Component, OnInit, Input } from '@angular/core';
import { environment } from '@sparro/env/environment';

@Component({
  selector: 'uni-competition-card-content',
  templateUrl: './competition-card-content.component.html',
  styleUrls: ['./competition-card-content.component.scss']
})
export class CompetitionCardContentComponent implements OnInit {
  @Input() competition: any;

  constructor() { }

  ngOnInit() {
  }

  openDetailView() {
    return `${environment.competitionUrl}${this.competition.slug}`;
  }

}
